import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Main_Home from '@app/modules/main_home';
import Main_Detail from '@app/modules/main_detail';

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" render={(props) => <Main_Home {...props} />} />
        <Route exact path="/project/:project_slug" render={(props) => <Main_Detail {...props} />} />
      </Switch>
    </Router>
  );
}

export default App;
