import { Select } from 'antd';
import { Container } from 'reactstrap';
import { useHomeContext } from '@app/modules/main_home/store';
import { useHistory } from "react-router-dom";

const { Option } = Select;

const Home_Search = (props) => {

  const history = useHistory();
  const [{ TABLE_DATA }] = useHomeContext();

  return (
    <Container>
      <div className="homepage-search">
        <div className="homepage-search-caption">
          <h4>CertiK Secured Projects</h4>
        </div>
        <div className="homepage-search-input">
          <Select
            style={{
              width: '100%',
              height: '100%'
            }}
            showSearch
            placeholder="Search project by name"
            optionLabelProp={"label"}
            onSelect={e => history.push(`/project/${e}`)}
            filterOption={(input, option) => option.label.toUpperCase().indexOf(input.toUpperCase()) >= 0}>
            {[...TABLE_DATA].map((data, key) => (
              <Option key={key} value={data.id} label={data.name}>
                <div className="search-label">
                  <div className="label-image">
                    <img src={data.icon ? `${process.env.REACT_APP_ENDPOINT + data.icon}` : 'https://via.placeholder.com/150'} />
                  </div>
                  {data.name}
                </div>
              </Option>
            ))}
          </Select>
        </div>
      </div>
    </Container>
  );
};

export default Home_Search;