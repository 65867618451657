import { createContext, useContext, useReducer } from 'react';
import { reducer } from '@app/modules/main_home/store/reducer';

const HomeContext = createContext();

const HomeContextProvider = ({ value, children }) => {
  const [store, dispatch] = useReducer(reducer, value)

  return (
    <HomeContext.Provider value={[store, dispatch]}>
      {children}
    </HomeContext.Provider>
  );
};

const useHomeContext = () => useContext(HomeContext);


export { HomeContext, HomeContextProvider, useHomeContext };