import React, { useEffect, useState } from 'react';
import * as model from '@app/modules/main_detail/model';
import View from '@app/modules/main_detail/view';
import Loader from '@app/modules/main_detail/loader'

/**
 * @function Main_Detail
 * @description this main home page
 * @property {}
 */
const Main_Detail = (props) => {
    const { match: { params } } = props
    /**
     * ----------------------------------
     * @description
     * init what data is needed on
     * homepage
     * 
     * put on useEffect
     * ----------------------------------
     */
    const [state, setState] = useState({ loading: true, data: [] })


    /**
     * ----------------------------------
     * @description
     * init what data is needed on
     * homepage
     * 
     * put on useEffect
     * ----------------------------------
     */
    useEffect(() => {
        model.axiosGetProjectDetail(params.project_slug).then((response) => {
            console.log(response)
            setState({
                loading: false,
                data: response.status === "success" ? response.data : {}
            })
        })
    }, [])

    if (state.loading) return <Loader />
    return <View data={state.data} />
}
export default Main_Detail;