import { RingProgress } from '@ant-design/charts';


const Home_RingProgress = ({ score }) => {

  var config = {
    height: 40,
    width: 40,
    autoFit: false,
    percent: parseFloat(score / 100),
    color: ['#F4664A', '#E8EDF3'],
    innerRadius: 0.75,
    radius: 0.98,
    text: "progress",
    statistic: {
      title: {
        style: {
          color: '#363636',
          fontSize: '12px',
          fontWeight: '600',
          lineHeight: '14px',
          marginTop: '7px'
        },
        formatter: () => {
          return `${score}`;
        },
      },
      content: {
        style: {
          color: '#363636',
          fontSize: '12px',
          fontWeight: '300',
          lineHeight: '14px',
          display: 'none'
        },
        formatter: () => {
          return 'Security Score';
        },
      }
    },
  };

  return <RingProgress {...config} />;
};


export default Home_RingProgress;