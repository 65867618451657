import React, { useState } from 'react';
import { Container, TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import { Table, Progress } from 'antd';
import { columns, data } from '@app/components/project_detail_tabs/data_example';
import ProjectDetail_Chart from "@app/components/project_detail_chart";
import ProjectDetail_RingProgress from "@app/components/project_detail_ring_progress";
import ProjectDetail_MarketCap_DetailChart from '@app/components/project_detail_marketcap_chart';



/**
 * ---------------------------------------
 * @function ProjectDetail_Tabs
 * @property 
 * ---------------------------------------
 */
const ProjectDetail_Tabs = ({
	tab_security,
	tab_marketcap
}) => {
	const [activeTab, setActiveTab] = useState('1');
	const toggle = tab => { if (activeTab !== tab) setActiveTab(tab); }

	return (
		<div className="project-detail-tabs-container">
			{/**
			 * ---------------------------------------
			 * @description Tabs Navigation
			 * this is tab panel navigation to switch between tabs
			 * ---------------------------------------
			 */}
			<Nav tabs className="project-detail-tabs-navigation">
				<NavItem className={activeTab === '1' ? 'tabs-navigation active' : 'tabs-navigation'}>
					<NavLink onClick={() => { toggle('1'); }}>
						<span>Security Score</span>
					</NavLink>
				</NavItem>
				<NavItem className={activeTab === '2' ? 'tabs-navigation active' : 'tabs-navigation'}>
					<NavLink onClick={() => { toggle('2'); }}>
						<span>Market Cap</span>
					</NavLink>
				</NavItem>
			</Nav>

			{/**
			 * ---------------------------------------
			 * @description Tabs Content
			 * this is tab content which will be shown on frontend
			 * ---------------------------------------
			 */}
			<TabContent
				activeTab={activeTab}
				className="project-detail-tabs-content">
				<TabPane tabId="1">
					<Row>
						<Col sm="12">
							<div className="project-detail-score">
								<div className="project-detail-score-progress">
									<ProjectDetail_RingProgress score={tab_security.score} />
								</div>
								<div className="project-detail-score-chart">
									<p><span>Score Detail</span><br /><span>For more Info check the detail bellow this section</span></p>
									<ProjectDetail_Chart score_detail={tab_security.detail} />
								</div>
							</div>
						</Col>
					</Row>
				</TabPane>
				<TabPane tabId="2">
					<Row>
						<Col sm="12">
							<div className="project-detail-market-chart">
								<p><span>Score Detail</span><br /><span>For more Info check the detail bellow this section</span></p>
								<ProjectDetail_MarketCap_DetailChart marketcap={tab_marketcap.market_cap} />
							</div>
						</Col>
					</Row>
				</TabPane>
			</TabContent>
		</div>
	);
};

export default ProjectDetail_Tabs;