import { useReff } from 'react';
import OwlCarousel from 'react-owl-carousel2';
import { Container, Row, Col } from 'reactstrap';
import { useHomeContext } from '@app/modules/main_home/store';

const Home_OnboardingProjects = () => {
  const Home_OnboardingProjects_Options = {
    margin: 10,
    loop: false,
    autoWidth: true,
    item: 3,
    dots: false
  };

  const [{ PROJECT_ONBOARDING }] = useHomeContext();

  return (
    <div className="home-onboarding_projects">
      <Container>
        <Row>
          <Col md={"3"} xs={"12"}>
            <div className="onboarding_projects_title">
              <span>On Boarding Projects</span>
            </div>
          </Col>
          <Col md={"9"} xs={"12"}>
            <OwlCarousel options={Home_OnboardingProjects_Options} >
              {[...PROJECT_ONBOARDING].map((data, key) => {
                return (
                  <div className="onboarding_projects" key={key}>
                    <div className="onboarding_projects_images">
                      <img src={data.icon ? `${process.env.REACT_APP_ENDPOINT + data.icon}` : 'https://via.placeholder.com/150'} alt={data.name} loading="lazy" />
                    </div>
                    <span className="ant-typography">{data.name}</span>
                  </div>
                )
              })}
            </OwlCarousel>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Home_OnboardingProjects;