import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import { FaGithub } from "react-icons/fa";
import { AiOutlineEye } from 'react-icons/ai';
import { Progress } from "antd"
import ProjectDetail_Tabs from "@app/components/project_detail_tabs";


const ProjectDetail_Detail = ({
  project_date,
  project_icon,
  project_name,
  project_sosmed
}) => {
  console.log(project_icon)
  return (
    <Row className="project-detail">
      <Col md="8">
        <div className="project-title">
          <img src={project_icon ? `${process.env.REACT_APP_ENDPOINT + '/' + project_icon}` : "https://via.placeholder.com/150"} alt="" />
          <h3>{project_name}</h3>
          <span className="project-rank">#Rank 3</span>
          <span className="project-boarding-date">On Boarded: {project_date}</span>
        </div>
      </Col>
      <Col md="4">
        <div className="project-rates">
          <div className="project-social-media">
            <ul>
              {Object.keys(project_sosmed).map((data => {
                return (
                  <li>
                    <a href={project_sosmed[data]}>
                      <img src={`https://www.certik.org/4d6380-${data}.svg`} />
                    </a>
                  </li>
                )
              }))}
            </ul>
          </div>
        </div>
      </Col>
    </Row>

  );
};

export default ProjectDetail_Detail;