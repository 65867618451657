import React, { useEffect, useState } from 'react';
import * as model from '@app/modules/main_home/model';
import View from '@app/modules/main_home/view';
import Certik_Loader from '@app/modules/main_home/loader';
import { HomeContextProvider } from '@app/modules/main_home/store';



/**----------------------------------
 * @function Main_Home
 * @description this main home page
 * @property {}
 * ----------------------------------
 */
const Main_Home = () => {

    /**
     * ----------------------------------
     * @description
     * init what data is needed on
     * homepage
     * 
     * put on useEffect
     * ----------------------------------
     */
    const [state, setState] = useState({
        loading: true, data: {
            PROJECT_LIST: [],
            PROJECT_ONBOARDING: []
        }
    })


    /**
     * ----------------------------------
     * @description
     * init what data is needed on
     * homepage
     * 
     * put on useEffect
     * ----------------------------------
     */
    useEffect(() => {
        Promise.all([
            model.axiosGetProjectList(),
            model.axiosGetProjectOnBoarding()
        ]).then((data) => {
            let status = true;
            const [PROJECT_LIST, PROJECT_ONBOARDING] = data;

            data.forEach(value => {
                if (value.status === 'failed') { status = false; }
            });

            if (status) {
                setTimeout(() => {
                    setState({
                        loading: false,
                        data: {
                            PROJECT_LIST: PROJECT_LIST.data,
                            PROJECT_ONBOARDING: PROJECT_ONBOARDING.data
                        }
                    })
                }, 3000)
            }
        })
    }, [])

    if (state.loading) return <Certik_Loader />
    return (
        <HomeContextProvider value={{
            TABLE_DATA: [...state.data.PROJECT_LIST],
            PROJECT_ONBOARDING: [...state.data.PROJECT_ONBOARDING]
        }}>
            <View />
        </HomeContextProvider>
    );
}
export default Main_Home;