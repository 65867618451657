import { Container, Row, Col } from 'reactstrap';
import { AutoComplete } from 'antd';
import Layout from '@app/components/main_layout';
import Footer from '@app/components/main_footer';
import Home_Banner from '@app/components/home_banner';
import Home_Tabs from '@app/components/home_tabs';
import Home_OnboardingProjects from '@app/components/home_onboarding_projects';
import Home_CommunityUpdates from '@app/components/home_community_updates';
import Home_Search from '@app/components/home_search';

/**
 * ----------------------------------------------------------
 * @function View
 * @description this components contains template on frontend
 * @property {data}
 * ----------------------------------------------------------
 */
const View = (props) => {
	return (
		<Layout>
			<Home_Banner />
			<Home_Search />
			<Home_OnboardingProjects />

			<Container>
				<Row>
					<Col md="9">
						<Home_Tabs />
					</Col>
					<Col md="3">
						<Row>
							<Col md="12">
								<Home_CommunityUpdates />
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
			<Footer />
		</Layout>
	)
}

export default View;