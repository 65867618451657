
import {
	AreaChart,
	Area,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer
} from "recharts";

const ProjectDetail_MarketCap_DetailChart = ({ marketcap }) => {
	const data = [...marketcap];

	return (
		<ResponsiveContainer width="100%" height={180}>
			<AreaChart
				data={data}
				margin={{
					top: 0,
					right: 0,
					bottom: 0,
					left: -35,
				}}
			>
				<YAxis dataKey="value" />
				<Tooltip />
				<Area type="monotone" dataKey="value" stroke="#8884d8" fill="#8884d8" />
			</AreaChart>
		</ResponsiveContainer>
	);
}

export default ProjectDetail_MarketCap_DetailChart;