import { RingProgress } from '@ant-design/charts';


const ProjectDetail_RingProgress = ({ score }) => {

  var config = {
    height: 150,
    width: 150,
    autoFit: false,
    percent: parseFloat(score / 100),
    color: ['#F4664A', '#E8EDF3'],
    innerRadius: 0.75,
    radius: 0.98,
    text: "progress",
    statistic: {
      title: {
        style: {
          color: '#000000',
          fontSize: '18px',
          fontWeight: '600',
          lineHeight: '14px',
        },
        formatter: () => {
          return `${score}`;
        },
      },
      content: {
        style: {
          color: '#000000',
          fontSize: '12px',
          fontWeight: '300',
          lineHeight: '14px',
        },
        formatter: () => {
          return 'Security Score';
        },
      }
    },
  };

  return <RingProgress {...config} />;
};


export default ProjectDetail_RingProgress;