import { memo } from 'react';
import { useHomeContext } from '@app/modules/main_home/store';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Media, Alert } from 'reactstrap';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

const Home_CommunityUpdates = (props) => {
  const [{ TABLE_DATA }] = useHomeContext();
  const COMMUNNITY_DATA = [];
  for (var i = 0; i < Math.ceil((TABLE_DATA.length / 3) - 1); i++) {
    COMMUNNITY_DATA.push(
      TABLE_DATA.slice().splice((i * Math.ceil(TABLE_DATA.length / 3)), Math.ceil(TABLE_DATA.length / 3))
    )
  }

  return (
    <div className="home-community_updates">
      <div className="home-community_updates_heading">
        <h4>Certik Community Updates</h4>
      </div>
      <div className="home-community_updates_content">
        <Swiper slidesPerView={1} spaceBetween={15}>

          {COMMUNNITY_DATA.map((split, key) => (
            <SwiperSlide key={key}>
              {split.map((data, index) => (
                <Media className="community-updates community-updates_confirmed">
                  <Media left middle className="mr-2">
                    <Media object src="https://www.certik.org/alert-circle-logo.svg" alt="Generic placeholder image" />
                  </Media>
                  <Media body>
                    <Media heading>
                      {data.name}
                    </Media>
                    <Alert color="danger" className="update-progress">
                      {data.status}
                    </Alert>
                  </Media>
                  <Media right middle className="ml-2 text-right">
                    <span>Updated At</span><br />
                    <span>{new Date(data.updated_at).toLocaleString()}</span>
                  </Media>
                </Media>
              ))}
            </SwiperSlide>
          ))}

        </Swiper>
      </div>
    </div>
  );
};

export default memo(Home_CommunityUpdates);
