import Loader from "react-loader-spinner";

const Certik_Loader = () => {
  return <div className="certik-loader">
    <Loader
      type="TailSpin"
      color="#e1aa4c"
      height={100}
      width={100}
    />
  </div>
};

export default Certik_Loader;