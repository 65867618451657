import axios from 'axios';


const APP_URL = process.env.REACT_APP_ENDPOINT;
const ENDPOINT_LIST = {
    sysfixed_projectList: `${APP_URL}/api/project`,
    sysfixed_projectOnboarding: `${APP_URL}/api/project?per_page=8&order=updated_at&order=desc`
};

/**
 * ----------------------------------------------------
 * @function axiosGetProjectList
 * @description
 * handle all api calls and fetching data on mounted
 * ----------------------------------------------------
 */
export const axiosGetProjectList = async () => {
    try {
        const response = await axios.get(ENDPOINT_LIST.sysfixed_projectList);
        if (response.status !== 200) throw response;
        return {
            status: 'success',
            message: 'Fetch Project Success',
            data: response.data.data
        };
    } catch (err) {
        return {
            status: 'failed',
            message: 'Fetch Project Failed, Contact Administrator',
            data: err.data.data
        };
    }
}



/**
 * ----------------------------------------------------
 * @function axiosGetProjectOnBoarding
 * @description
 * handle all api calls and fetching data on mounted
 * ----------------------------------------------------
 */
export const axiosGetProjectOnBoarding = async () => {
    try {
        const response = await axios.get(ENDPOINT_LIST.sysfixed_projectOnboarding);
        if (response.status !== 200) throw response;
        return {
            status: 'success',
            message: 'Fetch Project Success',
            data: response.data.data
        };
    } catch (err) {
        return {
            status: 'failed',
            message: 'Fetch Project Failed, Contact Administrator',
            data: err.data.data
        };
    }
}
