import React, { useState } from 'react';
import {
	Container,
	TabContent,
	TabPane,
	Nav,
	NavItem,
	NavLink,
	Card,
	Button,
	CardTitle,
	CardText,
	Row,
	Col
} from 'reactstrap';
import { Table, } from 'antd';
import {
	columns,
	data as exampleData
} from '@app/components/home_tabs/data_example';
import { useHomeContext } from '@app/modules/main_home/store';
import * as helper from '@app/modules/main_home/helper';


/**
 * ---------------------------------------
 * @function RenderTableHeader
 * @property Components
 * ---------------------------------------
 */
const RenderTableHeader = (props, columns) => {
	return (
		<tr>
			{columns.map((item, idx) => {
				if (item.visible) {
					return props.children[idx]
				}
			})}
		</tr>
	);
};



/**
 * ---------------------------------------
 * @function RenderTableBody
 * @property Components
 * ---------------------------------------
 */
const RenderTableBody = (props, columns) => {
	return (
		<tr>
			{columns.map((item, idx) => {
				if (item.visible) {
					return props.children[idx]
				}
			})}
		</tr>
	);
};



/**
 * ---------------------------------------
 * @function Home_Tabs
 * @property {}
 * ---------------------------------------
 */
const Home_Tabs = (props) => {
	const [activeTab, setActiveTab] = useState('1');
	const toggle = tab => { if (activeTab !== tab) setActiveTab(tab); }

	const [{ TABLE_DATA }] = useHomeContext();
	const [column, data] = helper.useGenerateTableData(TABLE_DATA)

	return (
		<div className="home-tabs-container">
			<Nav tabs className="home-tabs-navigation">
				<NavItem className={activeTab === '1' ? 'tabs-navigation active' : 'tabs-navigation'}>
					<NavLink onClick={() => { toggle('1'); }}>
						<img
							src={"https://www.certik.org/ranking-list-all.svg"}
							alt={"Leading Project"} />
						<span>Leading Project</span>
					</NavLink>
				</NavItem>
			</Nav>
			<TabContent activeTab={activeTab} className="home-tabs-content">
				<TabPane tabId="1">
					<Row>
						<Col sm="12">
							<Table
								columns={column}
								dataSource={data}
								rowkey="id"
								pagination={{ pageSize: 20 }}
								scroll={{ x: 760 }}
								components={{
									header: {
										row: (props) => RenderTableHeader(props, column)
									},
									body: {
										row: (props) => RenderTableBody(props, column)
									}
								}}
							/>
						</Col>
					</Row>
				</TabPane>
			</TabContent>
		</div>
	);
};

export default Home_Tabs;