import { Link } from 'react-router-dom';
import {
  LineChart,
  Line,
  CartesianGrid,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import Home_RingProgress from '@app/components/home_ring_progress'

/**
 * -----------------------------------------
 * @descrition generateTableData on homepage
 * @returns {table_column, data} tableData
 * -----------------------------------------
 */
export const useGenerateTableData = (payload) => {

  const TABLE = {
    column: null,
    data: null
  };

  // THIS SECTION TO CONFIGURE TABLE DATA
  TABLE.data = payload;

  // THIS SECTION TO CONFIGURE TABLE table_column TITLE
  TABLE.column = Object.keys(payload[0]).map((column, index, self) => {
    if (column === "icon" || column === "status" || column === "created_at" || column === "updated_at") {
      return {
        title: column.replace(/_/g, ' ').toUpperCase(),
        key: column,
        dataIndex: column,
        visible: false
      }
    }

    return {
      title: column.replace(/_/g, ' ').toUpperCase(),
      key: column,
      dataIndex: column,
      visible: true,
      render: (value, record) => {
        if (Array.isArray(value) && column === 'market_caps') {
          const data = value;
          return (
            <ResponsiveContainer width="100%" height={40}>
              <AreaChart
                height={40}
                data={data}
                key={column}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
              >
                <Area type="monotone" dataKey="value" stroke="#82ca9d" fill="#82ca9d" />
              </AreaChart>
            </ResponsiveContainer>
          )
        }

        if (Array.isArray(value) && column === 'score_detail') {
          const data = value;

          return (
            <ResponsiveContainer width="100%" height={40}>
              <AreaChart
                height={40}
                data={data}
                key={column}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
              >
                <Area type="monotone" dataKey="score" stroke="#82ca9d" fill="#82ca9d" />
              </AreaChart>
            </ResponsiveContainer>
          )
        }

        if (column === 'created_at' || column === "updated_at") {
          var date = new Date(value);
          return <p
            key={column}
            style={{ textAlign: 'center' }}>
            {date.toLocaleString()}
          </p>
        }

        if (column === 'score') {
          return <Home_RingProgress score={value} key={column} />
        }

        if (column === "name") {
          return <div className="project-icon">
            <div className="project-icon-img">
              <img src={record.icon ? `${process.env.REACT_APP_ENDPOINT + record.icon}` : 'https://via.placeholder.com/150'} />
            </div>
            <Link to={`/project/${record.id}`}>
              {value}
            </Link>
          </div>
        }

        return <p key={column} >{value}</p>;
      }
    }
  })

  return [TABLE.column, TABLE.data]
}