import { Tag, Space, Progress } from 'antd';
/**
 * ---------------------------------------
 * @variable columns
 * @description this variable used to store
 * title on thead (th) and the options 
 * on ant table
 * @returns [{object},...]
 * ---------------------------------------
 */
const columns = [
  {
    title: 'No',
    dataIndex: 'key',
    key: 'key',
    render: text => <a>{text}</a>,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: text => <a>{text}</a>,
  },
  {
    title: 'Age',
    dataIndex: 'age',
    key: 'age',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'Progress',
    dataIndex: 'progress',
    key: 'progress',
    render: (progress) => {
      return <Progress type="circle" percent={progress} width={30} strokeWidth={10} />
    }
  },
  {
    title: 'Tags',
    key: 'tags',
    dataIndex: 'tags',
    render: tags => (
      <>
        {tags.map(tag => {
          let color = tag.length > 5 ? 'geekblue' : 'green';
          if (tag === 'loser') {
            color = 'volcano';
          }
          return (
            <Tag color={color} key={tag}>
              {tag.toUpperCase()}
            </Tag>
          );
        })}
      </>
    ),
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Space size="middle">
        <a>Invite {record.name}</a>
        <a>Delete</a>
      </Space>
    ),
  },
];

/**
 * ---------------------------------------
 * @variable data
 * @description this variable used to store
 * data trial to be paste on table
 * @returns [{object},...]
 * ---------------------------------------
 */
const data = [
  {
    key: '1',
    name: 'John Brown',
    age: 32,
    address: 'New York No. 1 Lake Park',
    progress: 75,
    tags: ['nice', 'developer'],
  },
  {
    key: '2',
    name: 'Jim Green',
    age: 42,
    address: 'London No. 1 Lake Park',
    progress: 75,
    tags: ['loser'],
  },
  {
    key: '3',
    name: 'Joe Black',
    age: 32,
    address: 'Sidney No. 1 Lake Park',
    progress: 75,
    progress: 75,
    tags: ['cool', 'teacher'],
  },
  {
    key: '4',
    name: 'John Brown',
    age: 32,
    address: 'New York No. 1 Lake Park',
    progress: 75,
    progress: 75,
    tags: ['nice', 'developer'],
  },
  {
    key: '5',
    name: 'Jim Green',
    age: 42,
    address: 'London No. 1 Lake Park',
    progress: 75,
    tags: ['loser'],
  },
  {
    key: '6',
    name: 'Joe Black',
    age: 32,
    address: 'Sidney No. 1 Lake Park',
    progress: 75,
    tags: ['cool', 'teacher'],
  },
  {
    key: '7',
    name: 'John Brown',
    age: 32,
    address: 'New York No. 1 Lake Park',
    progress: 75,
    tags: ['nice', 'developer'],
  },
  {
    key: '8',
    name: 'Jim Green',
    age: 42,
    address: 'London No. 1 Lake Park',
    progress: 75,
    tags: ['loser'],
  },
  {
    key: '9',
    name: 'Joe Black',
    age: 32,
    address: 'Sidney No. 1 Lake Park',
    progress: 75,
    tags: ['cool', 'teacher'],
  },
  {
    key: '10',
    name: 'John Brown',
    age: 32,
    address: 'New York No. 1 Lake Park',
    progress: 75,
    tags: ['nice', 'developer'],
  },
  {
    key: '11',
    name: 'Jim Green',
    age: 42,
    address: 'London No. 1 Lake Park',
    progress: 75,
    tags: ['loser'],
  },
  {
    key: '12',
    name: 'Joe Black',
    age: 32,
    address: 'Sidney No. 1 Lake Park',
    progress: 75,
    tags: ['cool', 'teacher'],
  },
  {
    key: '13',
    name: 'John Brown',
    age: 32,
    address: 'New York No. 1 Lake Park',
    progress: 75,
    tags: ['nice', 'developer'],
  },
  {
    key: '14',
    name: 'Jim Green',
    age: 42,
    address: 'London No. 1 Lake Park',
    progress: 75,
    tags: ['loser'],
  },
  {
    key: '15',
    name: 'Joe Black',
    age: 32,
    address: 'Sidney No. 1 Lake Park',
    progress: 75,
    tags: ['cool', 'teacher'],
  },
  {
    key: '16',
    name: 'John Brown',
    age: 32,
    address: 'New York No. 1 Lake Park',
    progress: 75,
    tags: ['nice', 'developer'],
  },
  {
    key: '17',
    name: 'Jim Green',
    age: 42,
    address: 'London No. 1 Lake Park',
    progress: 75,
    tags: ['loser'],
  },
  {
    key: '18',
    name: 'Joe Black',
    age: 32,
    address: 'Sidney No. 1 Lake Park',
    progress: 75,
    tags: ['cool', 'teacher'],
  },
];


export { columns, data }