import { Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import { FaGithub } from "react-icons/fa";
import { AiOutlineEye } from 'react-icons/ai';

const ProjectDetail_Audit = ({
  project_audits_published
}) => {


  return (
    <>
      {project_audits_published.map((data, index) => {

        const created_at = new Date(data.created_at);
        const updated_at = new Date(data.updated_at);

        return (
          <Row className="project-audit" key={index}>
            <Col md="12">
              <div className="project-audit-title">
                <h4>
                  <img src="https://www.certik.org/audit-icon-light.svg" />
                  <span className="title">{`Audit History ${index + 1}`}</span>
                  <span className="description">{data.description}</span>
                </h4>
                <button>
                  <span>Request CertiK Audits</span>
                </button>
              </div>
              <div className="project-audit-content">
                <Card>
                  <CardBody>
                    <div className="project-audit-left">
                      <div className="project-audit-github">
                        <div className="project-audit-github-left">
                          <p className="project-audit-name">pancakeswab</p>
                          <p className="project-audit-date">Request Date: 2020/10/05</p>
                        </div>
                        <div className="project-audit-github-right">
                          <FaGithub size={30} />
                        </div>
                      </div>
                      <div className="project-audit-file">
                        <div className="project-audit-file-box">
                          <img src="https://certik.org/audits-placeholder.svg" />
                          <p>
                            CertiK has provided
                            1 audit for the project
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="project-audit-right">
                      <div className="project-audit-right-head">
                        <h4>{data.name} <FaGithub size={30} /></h4>
                        <button>
                          <AiOutlineEye size={20} />
                          <span>Request CertiK Audits</span>
                        </button>
                      </div>
                      <div className="project-audit-right-content">
                        <div className="project-audit-type">
                          <span>{data.status}</span>
                        </div>
                        <div className="project-audit-detail">
                          <div className="project-audit-detail-box">
                            <span className="title">Type</span>
                            <span className="detail">{data.type}</span>
                          </div>
                          <div className="project-audit-detail-box">
                            <span className="title">Language</span>
                            <span className="detail">{data.language}</span>
                          </div>
                          <div className="project-audit-detail-box">
                            <span className="title">REQUEST DATE</span>
                            <span className="detail">{created_at.toLocaleString()}</span>
                          </div>
                          <div className="project-audit-detail-box">
                            <span className="title">REVISION DATE</span>
                            <span className="detail">{updated_at.toLocaleString()}</span>
                          </div>
                        </div>
                        <div className="project-audit-description">
                          <span className="title">Description</span>
                          <span className="detail">
                            {data.description}
                          </span>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        );
      })}
    </>

  );
};


export default ProjectDetail_Audit;