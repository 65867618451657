
import {
	AreaChart,
	Area,
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer
} from "recharts";

const ProjectDetail_Chart = ({ score_detail }) => {
	const data = [...score_detail];

	return (
		<ResponsiveContainer width="100%" height={180}>
			<AreaChart
				data={data}
				margin={{
					top: 0,
					right: 0,
					bottom: 0,
					left: -35,
				}}
			>
				<XAxis dataKey="name" />
				<YAxis dataKey="score" />
				<Tooltip />
				<Area type="monotone" dataKey="score" stroke="#8884d8" fill="#8884d8" />
			</AreaChart>

		</ResponsiveContainer>
	);
}

export default ProjectDetail_Chart;