import Header from '@app/components/main_header';

/**
 * @function Layout
 * @description this main home page
 * @property {children}
 */

const Layout = ({ children }) => {
	return (
		<div className="page-wrapper certik-theme">
			<Header />
			<div className="maincontent">
				{children}
			</div>
		</div>
	)
}

export default Layout;