import axios from 'axios';


const APP_URL = process.env.REACT_APP_ENDPOINT;
const ENDPOINT_DETAIL = {
    sysfixed_projectDetail: (id) => `${APP_URL}/api/project${id}`,
};

/**
 * ----------------------------------------------------
 * @function INITIALIZE
 * @description
 * handle all api calls and fetching data on mounted
 * data will be returned as Object
 * { 
 *  created_at, 
 *  icon, 
 *  id, 
 *  link_sc, 
 *  market_caps, 
 *  name, 
 *  project_audits_published, 
 *  protocol_sc, 
 *  score, 
 *  score_detail, 
 *  sosmed, 
 *  status, 
 *  twitter_activities
 * }
 * ----------------------------------------------------
 */
export const axiosGetProjectDetail = async (data) => {
    try {
        const response = await axios.get(ENDPOINT_DETAIL.sysfixed_projectDetail(`/${data}`));
        if (response.status !== 200) throw response;
        return {
            status: 'success',
            message: 'Fetch Project Detail Success',
            data: response.data
        };
    } catch (err) {
        return {
            status: 'failed',
            message: 'Fetch Project Detail Failed, Contact Administrator',
            data: err
        };
    }
}
