import { Container } from "reactstrap";
import Layout from "@app/components/main_layout";
import Footer from "@app/components/main_footer";
import ProjectDetail_Skynet from "@app/components/project_detail_skynet";
import ProjectDetail_Audit from "@app/components/project_detail_audit";
import ProjectDetail_Detail from "@app/components/project_detail_detail";
import ProjectDetail_Description from "@app/components/project_detail_description";


/**
 * ----------------------------------------------------------
 * @function View
 * @description this components contains template on frontend
 * @property {data}
 * ----------------------------------------------------------
 */
const View = ({ data }) => {
	const PROJECT_DETAIL_PROPS = {
		project_name: data.name,
		project_date: data.created_at,
		project_sosmed: data.sosmed,
		project_icon: data.icon
	};


	const PROJECT_DETAIL_DESCRIPTION_PROPS = {
		project_score: data.score,
		project_score_detail: data.score_detail,
		project_market_cap: data.market_caps
	}

	const PROJECT_DETAIL_AUDIT_PROPS = {
		project_audits_published: data.project_audits_published
	};
	console.log(PROJECT_DETAIL_PROPS)

	return (
		<Layout>
			<div id="project-detail">
				<Container>
					<ProjectDetail_Detail {...PROJECT_DETAIL_PROPS} />
					<ProjectDetail_Description {...PROJECT_DETAIL_DESCRIPTION_PROPS} />
					<ProjectDetail_Audit {...PROJECT_DETAIL_AUDIT_PROPS} />
				</Container>
			</div>
			<Footer />
		</Layout>
	)
}

export default View;