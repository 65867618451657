import React, { useState } from 'react';
import { Container, Col, Row } from 'reactstrap';

/** 
 * -----------------------------------------------
 * @function Footer
 * @description this header for certik duplicate
 * @property {}
 * -----------------------------------------------
 */
const Footer = () => {
	return (
		<footer className="certik-footer">
			<Container>
				<Row>
					<Col
						md="3"
						xs="12"
						className="certik-footer-block certik-footer-desclaimer d-none d-sm-block">
						<img
							src="https://www.certik.org/certik-logotype-h-w.svg"
							alt="Certik Dumplicate" />
						<p>Copyright &copy; by CertiK. All rights reserved</p>
						<p>Desclaimer</p>
					</Col>
					<Col
						md="2"
						xs="4"
						className="certik-footer-block certik-footer-directory">
						<div className="certik-footer-block-title">
							<span>Direcotry</span>
						</div>
						<div className="certik-footer-block-content">
							<ul>
								<li><a href="#">Shield</a></li>
								<li><a href="#">Blog</a></li>
							</ul>
						</div>
					</Col>
					<Col
						md="2"
						xs="4"
						className="
					certik-footer-block certik-footer-build">
						<div className="certik-footer-block-title">
							<span>Build</span>
						</div>
						<div className="certik-footer-block-content">
							<ul>
								<li><a href="#">Documentation</a></li>
								<li><a href="#">GitHub</a></li>
							</ul>
						</div>
					</Col>
					<Col
						md="2"
						xs="4"
						className="certik-footer-block certik-footer-tools">
						<div className="certik-footer-block-title">
							<span>Tools</span>
						</div>
						<div className="certik-footer-block-content">
							<ul>
								<li><a href="#">Explorer</a></li>
								<li><a href="#">DeepWallet</a></li>
							</ul>
						</div>
					</Col>
					<Col
						md="3"
						xs="12"
						className="certik-footer-block certik-footer-newsletter">
						<div className="newsletter-icon-wrapper">
							<div className="newsletter-icon">
								<a href="https://t.me/certikfoundation" rel="noopener noreferrer" target="_blank">
									<div><img width="50%" src="https://www.certik.org/telegram.svg" alt="Telegram" /></div>
								</a>
							</div>
							<div className="newsletter-icon">
								<a href="https://twitter.com/certikorg" rel="noopener noreferrer" target="_blank">
									<div><img width="50%" src="https://www.certik.org/twitter.svg" alt="Twitter" /></div>
								</a>
							</div>
							<div className="newsletter-icon">
								<a href="https://medium.com/certik-foundation" rel="noopener noreferrer" target="_blank">
									<div><img width="50%" src="https://www.certik.org/medium.svg" alt="Medium" /></div>
								</a>
							</div>
							<div className="newsletter-icon">
								<div><img width="50%" src="https://www.certik.org/wechat.svg" alt="WeChat" /></div>
							</div>
							<div className="newsletter-icon">
								<a href="https://discord.gg/CggeAUYfwx" rel="noopener noreferrer" target="_blank">
									<div><img width="50%" src="https://www.certik.org/discord.svg" alt="Discord" /></div>
								</a>
							</div>
						</div>
						<div className="newsletter-subscribe">
							<a href="#">Subscribe Newsletter</a>
							<p>Subscribe to our newsletter updates for info on chain upgrades, bug bounties, and other news</p>
						</div>
					</Col>
				</Row>
			</Container>
		</footer>
	)
}

export default Footer;