import { Container, Row, Col } from 'reactstrap';
/**
 * ---------------------------------------
 * @function Home_Banner
 * @property {data}
 * ---------------------------------------
 */
const Home_Banner = ({ data }) => {
	return (
		<div className="home-banner">
			<Container>
				<Row>
					<Col md="6" xs="12" className="home-banner-description">
						<h1 className="banner-title">Security Leaderboard</h1>
						<h1 className="banner-tagline">Provable Trust For All</h1>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default Home_Banner;