import React, { useState } from 'react';
import {
	Collapse,
	Container,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
	NavLink
} from 'reactstrap';

/**
 * @function Header
 * @description this header for certik duplicate
 * @property {}
 */
const Header = () => {
	/**
	 * ---------------------------------------------
	 * @description useState for handle collabsible toogle
	 * @returns [isOpen, setIsOpen]
	 * ---------------------------------------------
	 */
	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => setIsOpen(!isOpen);


	/**
	 * ---------------------------------------------
	 * @description menu list on navbar
	 * array of object with object property
	 * @returns [{label: '', path:''}, ...]
	 * ---------------------------------------------
	 */
	const menu = [
		{ label: 'CertiKShiled', path: '/' },
		{ label: 'Explorer', path: '/explorer' },
		{ label: 'DeepWallet', path: '/deepwallet' },
		{ label: 'Technology', path: '/technology' },
		{ label: 'Blog', path: '/blog' }
	];

	return (
		<Navbar fixed="top" dark expand="md">
			<Container>
				<NavbarBrand href="/"><img src="https://www.certik.org/certik-logotype-h-w.svg" alt="Certik Dumplicate" /></NavbarBrand>
				<NavbarToggler onClick={toggle} />
				<Collapse isOpen={isOpen} navbar>
					<Nav className="ml-auto" navbar>

						{/* mapping menu on navbar */}
						{menu.map((link, key) => <NavItem key={key}>
							<NavLink href={link.path} className="certik-link">{link.label}</NavLink>
						</NavItem>)}

					</Nav>
				</Collapse>
			</Container>
		</Navbar>
	)
}

export default Header;