import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import { FaGithub } from "react-icons/fa";
import { AiOutlineEye } from 'react-icons/ai';
import { Progress } from "antd"
import ProjectDetail_Tabs from "@app/components/project_detail_tabs";


const ProjectDetail_Description = ({
  project_score,
  project_score_detail,
  project_market_cap
}) => {

  const PROJECT_DETAIL_TABS_PROPS = {
    tab_security: {
      score: project_score,
      detail: project_score_detail
    },
    tab_marketcap: {
      market_cap: project_market_cap
    }
  };

  return (
    <Row className="project-description">
      <Col md="8">
        <ProjectDetail_Tabs {...PROJECT_DETAIL_TABS_PROPS} />
      </Col>
      <Col md="4">
        <div className="project-rates">
          <div className="project-key-statistics">
            <Card>
              <CardBody>
                <h6>Key Statistics</h6>
                <div className="key-statistics-wrapper">
                  <div className="key-statistic-left">
                    <p><span>Skynet</span><span>Active</span></p>
                    <p><span>Audits</span><span>1</span></p>
                    <p><span>Sheld</span><span>111,185 CTK</span></p>
                  </div>
                  <div className="key-statistic-right">
                    <p><span>Cap</span><span>$4,394,829,669</span></p>
                    <p><span>24h Rate</span><span>1</span></p>
                    <p><span>CAKE Price</span><span>$20.07</span></p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="project-security-rates">
            <Card>
              <CardBody>
                <div className="key-security-rates-wrapper">
                  <div className="key-security-rates-left">
                    <p>How do you feel about this project"s security today?</p>
                  </div>
                  <div className="key-security-rates-right">
                    <button>
                      <img src="https://www.certik.org/secure-icon.svg" alt="icon" />
                      <p>Secure</p>
                    </button>
                    <button>
                      <img src="https://www.certik.org/insecure-icon.svg" alt="icon" />
                      <p>Insecure</p>
                    </button>
                  </div>
                </div>
                <div className="key-security-progress-wrapper">
                  <Progress strokeLinecap="square" percent={75} strokeColor="#4d6380" trailColor="rgb(199, 206, 214)" />
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </Col>
    </Row>

  );
};

export default ProjectDetail_Description;